import React from 'react'

export const Logo = ({color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="72" viewBox="0 0 120 72" fill="none">
      <g clipPath="url(#clip0_398_153)">
          <path
            d="M20.7267 44.0344H16.0342V50.4417H20.7267C23.6712 50.4417 25.03 49.4713 25.03 47.2708C25.03 45.2317 23.6712 44.0344 20.7267 44.0344Z"
            fill="#330D17"/>
          <path
            d="M80.9142 -0.157715V15.2663H40.3779V30.6916H-0.157715V71.2279H40.3779V55.8026H80.9142V40.3781H121.45V-0.157715H80.9142ZM25.1917 61.1532C24.7708 60.6032 24.512 59.2113 24.3505 56.9464C24.1883 54.6485 23.1526 53.6134 20.8235 53.6134H16.0338V61.1532H12.0535V40.7663H21.2762C26.4862 40.7663 29.0418 43.1932 29.0418 46.9797C29.0418 50.054 27.0363 51.8336 24.5444 52.1573C26.8741 52.61 28.072 53.9695 28.2984 56.3641C28.5573 59.3734 28.6539 60.4086 29.3341 61.1532L25.1917 61.1532ZM61.1312 46.1164C55.8894 46.1164 52.1029 43.3982 52.1029 38.4799L55.8246 38.3178C55.9866 41.4894 58.2839 42.9131 61.1312 42.9131C63.6554 42.9131 65.2731 41.9096 65.2731 40.1948C65.2731 38.6415 64.0439 38.059 61.9082 37.6381L58.0251 36.9265C54.8536 36.3442 52.653 34.5963 52.653 31.2308C52.653 27.4125 55.6623 24.9531 60.4197 24.9531C65.7589 24.9531 68.7682 27.7038 68.833 32.2342L65.1115 32.3638C64.9819 29.5808 63.1697 28.1247 60.3872 28.1247C57.9921 28.1247 56.6333 29.1923 56.6333 30.9395C56.6333 32.4287 57.7008 32.9462 59.546 33.3023L63.2993 33.9814C67.3122 34.726 69.1892 36.6354 69.1892 39.936C69.1892 43.9487 65.7271 46.1164 61.1312 46.1164ZM110.598 30.3031H107.007V27.2938C106.003 29.5591 103.446 30.6916 100.534 30.6916C94.8391 30.6916 90.6 26.42 90.6 20.11C90.6 13.8323 95.098 9.52849 100.825 9.52849C106.715 9.52849 110.113 12.7642 110.534 17.9094L106.585 18.0715C106.326 15.0622 104.321 13.0879 100.923 13.0879C97.2019 13.0879 94.8061 15.8385 94.8061 20.11C94.8061 24.4789 97.2656 27.2614 101.247 27.2614C104.126 27.2614 106.23 25.6112 106.812 23.0222H101.408V20.0127H110.598V30.3031Z"
            fill="#330D17"/>
      </g>
      <defs>
          <clipPath id="clip0_398_153">
              <rect width="120" height="71.4894" fill="white"/>
          </clipPath>
      </defs>
  </svg>
)

export const LogoLight = ({color}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="120" height="72" viewBox="0 0 120 72" fill="none">
    <g clipPath="url(#clip0_398_124)">
      <path
        d="M20.7266 44.0344H16.0341V50.4417H20.7266C23.6711 50.4417 25.03 49.4713 25.03 47.2708C25.03 45.2317 23.6711 44.0344 20.7266 44.0344Z"
        fill="#FFEEEB"/>
      <path
        d="M80.9142 -0.157715V15.2663H40.378V30.6916H-0.1577V71.2279H40.378V55.8026H80.9142V40.3781H121.45V-0.157715H80.9142ZM25.1917 61.1532C24.7708 60.6032 24.512 59.2113 24.3505 56.9464C24.1883 54.6485 23.1526 53.6134 20.8235 53.6134H16.0338V61.1532H12.0535V40.7663H21.2762C26.4862 40.7663 29.0418 43.1932 29.0418 46.9797C29.0418 50.054 27.0363 51.8336 24.5444 52.1573C26.8741 52.61 28.072 53.9695 28.2984 56.3641C28.5573 59.3734 28.6539 60.4086 29.3341 61.1532L25.1917 61.1532ZM61.1312 46.1164C55.8894 46.1164 52.1029 43.3982 52.1029 38.4799L55.8246 38.3178C55.9866 41.4894 58.284 42.9131 61.1312 42.9131C63.6554 42.9131 65.2731 41.9096 65.2731 40.1948C65.2731 38.6415 64.0439 38.059 61.9082 37.6381L58.0251 36.9265C54.8536 36.3442 52.653 34.5963 52.653 31.2308C52.653 27.4125 55.6624 24.9531 60.4197 24.9531C65.7589 24.9531 68.7682 27.7038 68.833 32.2342L65.1115 32.3638C64.9819 29.5808 63.1697 28.1247 60.3873 28.1247C57.9921 28.1247 56.6334 29.1923 56.6334 30.9395C56.6334 32.4287 57.7008 32.9462 59.5461 33.3023L63.2993 33.9814C67.3122 34.726 69.1893 36.6354 69.1893 39.936C69.1893 43.9487 65.7271 46.1164 61.1312 46.1164ZM110.598 30.3031H107.007V27.2938C106.003 29.5591 103.446 30.6916 100.534 30.6916C94.8391 30.6916 90.6 26.42 90.6 20.11C90.6 13.8323 95.098 9.52849 100.826 9.52849C106.715 9.52849 110.113 12.7642 110.534 17.9094L106.585 18.0715C106.326 15.0622 104.321 13.0879 100.923 13.0879C97.2019 13.0879 94.8061 15.8385 94.8061 20.11C94.8061 24.4789 97.2656 27.2614 101.247 27.2614C104.126 27.2614 106.23 25.6112 106.812 23.0222H101.408V20.0127H110.598V30.3031Z"
        fill="#FFEEEB"/>
    </g>
    <defs>
      <clipPath id="clip0_398_124">
        <rect width="120" height="71.4894" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)









