import React from 'react'
import {Logo} from './icons'
import Link from "../utils/link";
import {navigate} from "gatsby";

const Header = ({pageContext, location}) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [sticky, setSticky] = React.useState(false)
  const [toggle, setToggle] = React.useState(location?.pathname.replace(/\//g, ''));
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })
  const [direction, setDirection] = React.useState('down')

  let props = {
    onClick: () => setOffCanvas(false),
    activeClassName: 'active',
  }

  let toggleProps = {
    onClick: () => setOffCanvas(false),
    activeClassName: 'active',
  }

  const openOffCanvas = () => {
    setOffCanvas(!offCanvas)
  }

  const closeMenu = () => {
    setOffCanvas(false)
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {

    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    const stickyHeader = document?.querySelector(".sticky-header")
    scr = curScroll > 1
    const dir = curScroll > prevScroll ? "down" : "up"

    if (Math.abs(prevScroll - curScroll) <= 5) return

    if (stickyHeader) {
      if (direction === "down") {
        setSticky(true)
        stickyHeader.classList.add("active")
      } else {
        setSticky(false)
        stickyHeader.classList.remove("active")
      }
    } else {
      setSticky(false)
    }

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  React.useEffect(() => {
    setToggle(location?.pathname.replace(/\//g, ''));
  }, [location?.pathname])

  React.useEffect(() => {
    window.addEventListener("scroll", scrollDetect, false)
    return () => {
      window.removeEventListener("scroll", scrollDetect, false)
    }
  }, [])

  let headerClass = "header"
  if (scrolled) headerClass += " header--scrolled"
  if (offCanvas) headerClass += " header--off-canvas"
  if (sticky) headerClass += " header--sticky"
  if ( direction === 'down' && scrolled ) headerClass += ' header--up'

  const toggleSwitch = (slug) => {
    setToggle(location?.pathname.replace(/\//g, ''));
    setTimeout(() => {
      if (slug === 'home' || slug === '/') {
        navigate('/');
      } else {
        navigate('/business');
      }
    }, 300);
  }

  React.useEffect(() => {
    const toggleElement = document.querySelector('.landing-banner__content .toggle');
    if (toggleElement) {
      // Remove previous class
      toggleElement.classList.remove(...toggleElement.classList);
      // Add the updated class
      toggleElement.classList.add('toggle', `toggle--${(toggle === 'home' || toggle === '') ? 'talent' : 'business'}`);
    }
  }, [toggle]);

  return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' className='header__logo' {...props}>
              <Logo/>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li className={`toggle toggle--${toggle}`}>
                  <button onClick={() => toggleSwitch('home')}>Talent</button>
                  <button onClick={() => toggleSwitch('business')}>Business</button>
                </li>
                <li><Link to='/team' {...props}>Team</Link></li>
                <li><Link to='/careers' {...props}>Jobs</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={() => openOffCanvas()} className={ `btn btn--light-maroon ${offCanvas ? 'header__hamburger active' : 'header__hamburger'}` }>
              {offCanvas ? 'Close' : 'Menu'}
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/' {...props}>Talent</Link></li>
                <li><Link to='/business' {...props}>Business</Link></li>
                <li><Link to='/careers' {...props}>Jobs</Link></li>
                <li><Link to='/team' {...props}>Team</Link></li>
                <li><Link to='/contact' {...props}>Let's Chat</Link></li>
              </ul>
            </nav>

            <div className='off-canvas__bottom'>
              <ul>
                <li><Link>hello@recruitsearchgrow.com.au</Link></li>
                <li><Link>03 9887 8963</Link></li>
                <li><Link>LinkedIn</Link></li>
                <li><Link>Instagram</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </>
  )
}

export default Header