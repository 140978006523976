import React, {useEffect, useRef} from 'react';
import Matter from 'matter-js';
import {pathSeg} from "../utils/pathseg";
import {gsap} from "gsap";

import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {once} from "lodash";

gsap.registerPlugin(ScrollTrigger);

const svgPathsDesktop = [
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.332275 0L146.657 0.332153L146.325 146.665L0.000104041 146.333L0.332275 0ZM58.6183 71.4299L58.6708 48.2999L75.6096 48.3383C86.2388 48.3625 91.134 52.6957 91.1173 60.0567C91.0993 68.0008 86.1864 71.4924 75.5571 71.4683L58.6183 71.4299ZM106.542 110.206C104.092 107.512 103.752 103.775 102.842 92.909C102.045 84.2629 97.732 79.3457 89.3259 77.6923C98.3236 76.5444 105.577 70.1363 105.603 59.0384C105.634 45.3698 96.4289 36.5876 77.6218 36.5449L44.3294 36.4693L44.1624 110.064L58.5305 110.097L58.5923 82.8789L75.8822 82.9182C84.2898 82.9372 88.0202 86.6826 88.5866 94.9792C89.1511 103.156 90.0738 108.183 91.5888 110.172L106.542 110.206Z" fill="#B25668"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.21167 0L146.539 0.211589L146.328 146.545L7.26468e-05 146.333L0.21167 0ZM42.4152 83.8605C42.4088 88.2992 43.2574 92.2426 44.8295 95.6616C49.5457 105.918 60.7739 111.454 74.9654 111.475C91.5553 111.499 104.064 103.692 104.085 89.2061C104.102 77.291 97.3369 70.3884 82.8555 67.6796L69.3103 65.2086C62.6514 63.9136 58.8004 62.0396 58.8081 56.664C58.8173 50.3564 63.7278 46.5096 72.3739 46.5221C82.4179 46.5367 88.9518 51.8024 89.4054 61.8497L102.84 61.4009C102.63 45.0464 91.7806 35.1011 72.5075 35.0732C55.3346 35.0484 44.4584 43.911 44.4385 57.6945C44.4209 69.8436 52.3556 76.1649 63.8011 78.284L77.8144 80.8728C85.5219 82.4034 89.9557 84.5124 89.9476 90.1199C89.9386 96.3104 84.0943 99.9239 74.9822 99.9108C64.7041 99.8959 56.4188 94.7446 55.8501 83.2947L42.4152 83.8605Z" fill="#66192B"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.0839844 0L146.409 0.0839534L146.325 146.417L2.64104e-05 146.333L0.0839844 0ZM35.0055 73.1869C34.9925 95.9658 50.286 111.395 70.8445 111.406C81.3567 111.412 90.5882 107.33 94.2147 99.1536L94.2085 110.017L107.174 110.025L107.196 72.8772L74.0203 72.8582L74.014 83.722L93.5213 83.7332C91.4135 93.0785 83.8174 99.0306 73.4222 99.0246C59.0518 99.0164 50.1796 88.9668 50.1886 73.1957C50.1975 57.7757 58.8515 47.851 72.2833 47.8587C84.549 47.8658 91.7843 54.9969 92.7122 65.8612L106.968 65.2841C105.457 46.7095 93.1981 35.0215 71.9395 35.0093C51.264 34.9974 35.0186 50.5251 35.0055 73.1869Z" fill="#4C121E"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.273926 0L146.599 0.273731L146.325 146.607L0.000179524 146.333L0.273926 0ZM35.1005 73.2322C35.0579 96.011 50.3314 111.46 70.8899 111.498C81.4021 111.518 90.6388 107.447 94.276 99.2756L94.2557 110.139L107.221 110.164L107.291 73.0161L74.1156 72.954L74.0953 83.8178L93.6026 83.8543C91.4826 93.1969 83.8788 99.1391 73.4836 99.1197C59.1133 99.0928 50.2541 89.0317 50.2836 73.2606C50.3125 57.8406 58.9793 47.9272 72.4111 47.9523C84.6768 47.9753 91.9028 55.1158 92.8167 65.9813L107.073 65.4227C105.586 46.8461 93.3425 35.1422 72.084 35.1024C51.4085 35.0638 35.1429 50.5704 35.1005 73.2322Z" fill="#94344A"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="148" viewBox="0 0 147 148" fill="none">' +
    '<path d="M0 0.683594L146.326 -2.39269e-05L147.01 146.332L0.683644 147.015L0 0.683594ZM42.7158 84.2844C42.7365 88.7229 43.6092 92.6612 45.2022 96.0704C49.9811 106.298 61.243 111.765 75.4343 111.699C92.0241 111.621 104.485 103.738 104.417 89.2525C104.362 77.3376 97.554 70.4765 83.0564 67.8564L69.4962 65.4682C62.8296 64.214 58.9672 62.3636 58.942 56.988C58.9126 50.6805 63.7995 46.8038 72.4455 46.7634C82.4894 46.7165 89.0554 51.9421 89.5705 61.9865L103.002 61.4555C102.692 45.1026 91.782 35.2238 72.5091 35.3139C55.3363 35.3941 44.5145 44.3231 44.5789 58.1064C44.6357 70.2555 52.6089 76.5281 64.0672 78.5771L78.0961 81.0801C85.8128 82.5636 90.2594 84.6454 90.2856 90.2528C90.3145 96.4432 84.4923 100.092 75.3804 100.135C65.1023 100.183 56.7857 95.0826 56.147 83.6364L42.7158 84.2844Z" fill="#94344A"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="148" height="148" viewBox="0 0 148 148" fill="none">' +
    '<path d="M0.594971 0.947266L146.92 0.593957L147.273 146.927L0.948298 147.28L0.594971 0.947266ZM59.215 72.1033L59.1591 48.9733L76.0979 48.9324C86.7271 48.9068 91.6426 53.217 91.6603 60.5781C91.6795 68.5222 86.783 72.0367 76.1538 72.0624L59.215 72.1033ZM107.32 110.654C104.858 107.973 104.5 104.237 103.539 93.375C102.701 84.7328 98.3653 79.8359 89.9516 78.2219C98.9438 77.0318 106.168 70.5897 106.141 59.4919C106.108 45.8233 96.8619 37.0843 78.0548 37.1297L44.7625 37.2101L44.9402 110.805L59.3083 110.77L59.2426 83.5523L76.5325 83.5106C84.9401 83.4903 88.688 87.2181 89.2932 95.5119C89.896 103.687 90.8423 108.709 92.3665 110.69L107.32 110.654Z" fill="#4C121E"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.463867 0L146.789 0.463957L146.325 146.797L-0.000114978 146.333L0.463867 0ZM35.1953 73.2774C35.123 96.0561 50.3764 111.525 70.9348 111.59C81.447 111.623 90.689 107.564 94.3368 99.3978L94.3024 110.261L107.268 110.303L107.386 73.1551L74.2107 73.0499L74.1762 83.9137L93.6834 83.9756C91.5513 93.3154 83.9398 99.2477 73.5447 99.2148C59.1744 99.1692 50.3283 89.0966 50.3783 73.3255C50.4272 57.9056 59.1069 48.0035 72.5387 48.0461C84.8043 48.085 92.021 55.2349 92.9207 66.1016L107.178 65.5615C105.715 46.983 93.4867 35.2632 72.2283 35.1958C51.5528 35.1302 35.2671 50.6157 35.1953 73.2774Z" fill="#66192B"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">' +
    '<path d="M0.373047 0L146.7 0.37313L146.327 146.706L-9.73042e-05 146.333L0.373047 0ZM42.484 83.9071C42.4727 88.3457 43.3169 92.2901 44.8852 95.7108C49.5902 105.973 60.8122 111.521 75.0037 111.557C91.5935 111.599 104.111 103.806 104.148 89.3207C104.178 77.4057 97.4205 70.4955 82.9422 67.7708L69.3996 65.2848C62.7422 63.9825 58.8932 62.1042 58.9069 56.7287C58.923 50.4211 63.8378 46.5797 72.4839 46.6018C82.5279 46.6274 89.0559 51.9003 89.4985 61.9482L102.934 61.5142C102.741 45.1594 91.9032 35.2022 72.6301 35.153C55.4573 35.1092 44.5713 43.9598 44.5361 57.7433C44.5052 69.8924 52.4328 76.2224 63.8761 78.3542L77.8865 80.9584C85.5923 82.4975 90.0237 84.6114 90.0094 90.2189C89.9936 96.4094 84.1453 100.016 75.0332 99.9932C64.7551 99.967 56.4755 94.8066 55.9195 83.3561L42.484 83.9071Z" fill="#7A2736"/>' +
    '</svg>'
];

const svgPathsMobile = [
    '<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">' +
    '<path d="M0 0.581909L79.0142 0.0845859L79.5115 79.103L0.49735 79.6004L0 0.581909ZM31.8036 38.8831L31.725 26.3931L40.8718 26.3355C46.6115 26.2994 49.2748 28.6166 49.2999 32.5915C49.3269 36.8813 46.6901 38.7894 40.9504 38.8255L31.8036 38.8831ZM57.8609 59.5997C56.5256 58.1567 56.3245 56.14 55.7831 50.2769C55.3125 45.6118 52.9609 42.9766 48.4141 42.1227C53.2674 41.4612 57.1547 37.9674 57.1169 31.9746C57.0705 24.5937 52.0595 19.8941 41.9038 19.958L23.9262 20.0712L24.1764 59.8117L31.935 59.7629L31.8425 45.0655L41.1789 45.0067C45.7189 44.9781 47.7506 46.9833 48.0948 51.4606C48.4374 55.8736 48.959 58.5836 49.7862 59.6505L57.8609 59.5997Z" fill="#B25668"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">' +
    '<path d="M0.915527 0.102051L79.9324 -3.43289e-05L80.0345 79.0199L1.01762 79.122L0.915527 0.102051ZM23.8293 45.3242C23.8324 47.721 24.2965 49.8493 25.1505 51.6931C27.7124 57.2248 33.7838 60.1975 41.4472 60.1876C50.4058 60.176 57.149 55.9419 57.1389 48.1196C57.1306 41.6854 53.467 37.968 45.6431 36.5267L38.325 35.2124C34.7274 34.5229 32.645 33.5167 32.6413 30.6139C32.6369 27.2078 35.2829 25.1233 39.9518 25.1172C45.3755 25.1102 48.9116 27.944 49.1714 33.3689L56.4254 33.1067C56.2876 24.2756 50.4145 18.9212 40.007 18.9346C30.7336 18.9466 24.8736 23.7485 24.8832 31.1916C24.8917 37.7521 29.1857 41.1539 35.3694 42.2812L42.9404 43.6585C47.1047 44.4736 49.5021 45.6059 49.506 48.6339C49.5103 51.9768 46.3597 53.9368 41.4392 53.9431C35.889 53.9503 31.4073 51.1808 31.0833 44.9988L23.8293 45.3242Z" fill="#66192B"/>' +
    '</svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">' +
    '<path d="M0 0.444824L79.0145 3.00826e-06L79.4593 79.0188L0.444845 79.4636L0 0.444824ZM19.1024 39.848C19.1717 52.1484 27.4817 60.4286 38.5831 60.3661C44.2596 60.3342 49.2309 58.0986 51.1618 53.6714L51.1948 59.5378L58.1962 59.4984L58.0833 39.439L40.1689 39.5398L40.2019 45.4062L50.7357 45.3469C49.6288 50.4003 45.5469 53.6398 39.9336 53.6714C32.1737 53.7151 27.3491 48.3182 27.3012 39.8019C27.2543 31.4752 31.8941 26.087 39.1472 26.0462C45.7706 26.0089 49.7014 29.8354 50.2389 35.6989L57.935 35.3395C57.0569 25.3145 50.398 19.0441 38.9185 19.1088C27.7539 19.1716 19.0336 27.6109 19.1024 39.848Z" fill="#94344A"/>' +
    '</svg>',
];

const PoolOfSquares = ({location}) => {
    const canvasRef = useRef(null);
    pathSeg();

    useEffect(() => {
        let engine, render, runner, world, observer;

        const initMatter = () => {
            if (canvasRef.current) {
                const existingCanvas = canvasRef.current.querySelector('canvas');
                if (existingCanvas) {
                    canvasRef.current.removeChild(existingCanvas);
                }
            }

            const Engine = Matter.Engine,
                Render = Matter.Render,
                Runner = Matter.Runner,
                Common = Matter.Common,
                MouseConstraint = Matter.MouseConstraint,
                Mouse = Matter.Mouse,
                Composite = Matter.Composite,
                Bodies = Matter.Bodies,
                Body = Matter.Body;

            engine = Engine.create({
                positionIterations: 50,
                velocityIterations: 50,
            });

            world = engine.world;
            runner = Runner.create();

            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;

            render = Render.create({
                element: canvasRef.current,
                engine: engine,
                options: {
                    width: viewportWidth,
                    height: viewportHeight,
                    showStats: false,
                    showPerformance: false,
                    wireframes: false,
                },
            });

            Render.run(render);
            Runner.run(runner, engine);

            const scale = 1.005;
            const isMobile = viewportWidth < 900;
            const svgPaths = isMobile ? svgPathsMobile : svgPathsDesktop;
            const size = isMobile ? 80 : 146;

            const squares = [];
            for (let i = 0; i < 18; i++) {
                const x = Common.random(50, viewportWidth - 50);
                const y = Common.random(-200, 0);

                const randomSvg = svgPaths[Math.floor(Math.random() * svgPaths.length)];

                const square = Bodies.fromVertices(x, y, [
                    {x: 0, y: 0},
                    {x: size * scale, y: 0},
                    {x: size * scale, y: size * scale},
                    {x: 0, y: size * scale},
                ], {
                    density: 0.001,
                    frictionAir: 0.001,
                    restitution: 0.5,
                    render: {
                        sprite: {
                            texture: 'data:image/svg+xml;utf8,' + encodeURIComponent(randomSvg),
                        },
                    },
                });
                squares.push(square);
            }

            const mouse = Mouse.create(render.canvas);
            const mouseConstraint = MouseConstraint.create(engine, {
                mouse: mouse,
                constraint: {
                    stiffness: 0.2,
                    render: {
                        visible: false,
                    },
                },
            });

            Matter.Events.on(mouseConstraint, 'mousedown', (event) => {
                const mousePosition = event.mouse.position;
                const x = mousePosition.x;
                const y = mousePosition.y;

                const clickedBodies = Matter.Query.point(squares, mousePosition);

                if (clickedBodies.length === 0) {
                    const randomSvg = svgPaths[Math.floor(Math.random() * svgPaths.length)];

                    const square = Bodies.fromVertices(x, y, [
                        {x: 0, y: 0},
                        {x: size * scale, y: 0},
                        {x: size * scale, y: size * scale},
                        {x: 0, y: size * scale},
                    ], {
                        density: 0.001,
                        frictionAir: 0.001,
                        restitution: 0.5,
                        render: {
                            sprite: {
                                texture: 'data:image/svg+xml;utf8,' + encodeURIComponent(randomSvg),
                            },
                        },
                    });
                    Composite.add(world, square);
                    squares.push(square);
                }
            });

            const maxSpeed = 50;

            Matter.Events.on(engine, 'afterUpdate', () => {
                squares.forEach((square) => {
                    const velocity = square.velocity;

                    const speed = Math.sqrt(velocity.x * velocity.x + velocity.y * velocity.y);

                    if (speed > maxSpeed) {
                        const scalingFactor = maxSpeed / speed;
                        Body.setVelocity(square, {
                            x: velocity.x * scalingFactor, y: velocity.y * scalingFactor
                        });
                    }
                });
            });

            const topWall = Bodies.rectangle(viewportWidth / 2, -200, viewportWidth, 50, {isStatic: true, render: {visible: false}});
            const bottomWall = Bodies.rectangle(viewportWidth / 2, viewportHeight, viewportWidth, 1, {isStatic: true, render: {visible: false}});
            const rightWall = Bodies.rectangle(viewportWidth, viewportHeight / 2, 1, viewportHeight, {isStatic: true, render: {visible: false}});
            const leftWall = Bodies.rectangle(0, viewportHeight / 2, 10, viewportHeight, {isStatic: true, render: {visible: false}});

            Composite.add(world, [
                topWall, leftWall, rightWall, bottomWall
            ]);
            Composite.add(world, squares);
            Composite.add(world, mouseConstraint);

            let scrollTimeout;

            function handleScroll() {
                if (render && render.canvas) {
                    render.canvas.style.pointerEvents = "none";
                    clearTimeout(scrollTimeout);
                    scrollTimeout = setTimeout(() => {
                        render.canvas.style.pointerEvents = "auto";
                    }, 200);
                }
            }

            render.canvas.addEventListener("wheel", handleScroll);

            render.mouse = mouse;

            Render.lookAt(render, {
                min: {x: 0, y: 0},
                max: {x: viewportWidth, y: viewportHeight},
            });

            const handleResize = () => {
                render.canvas.width = window.innerWidth;
                render.canvas.height = window.innerHeight;
                // Matter.Body.setPosition(bottomWall, Matter.Vector.create(viewportWidth / 2, viewportHeight + 50 / 2))
                // Matter.Body.setPosition(rightWall, Matter.Vector.create(viewportWidth + 50 / 2, viewportHeight / 2))

                Matter.Body.setPosition(bottomWall, {x: viewportWidth / 2, y: viewportHeight + 50})
                Matter.Body.setPosition(rightWall, {x: viewportWidth + 50, y: viewportHeight / 2})
            }

            window.addEventListener('resize', handleResize)
        }

        const trigger = ScrollTrigger.create({
            trigger: canvasRef.current,
            start: "100px bottom",
            onEnter: () => {
                initMatter();
            },
            once: true,
        });

        // const handleIntersection = (entries) => {
        //     const [entry] = entries;
        //     if (entry.isIntersecting) {
        //         initMatter();
        //     }
        // };
        //
        // const setupObserver = () => {
        //     observer = new IntersectionObserver(handleIntersection, {
        //         root: null, // observing in the viewport
        //         threshold: 0.1, // trigger when 10% is visible
        //     });
        //     observer.observe(canvasRef.current);
        // };

        // setupObserver();

        return () => {
            if (render) {
                Matter.Render.stop(render);
                Matter.Runner.stop(runner);
                engine.world.bodies = [];
                engine.world.constraints = [];
                const existingCanvas = canvasRef.current.querySelector('canvas');
                if (existingCanvas) {
                    canvasRef.current.removeChild(existingCanvas);
                }
            }
            trigger.kill();
            // if (observer) observer.disconnect();
        };
    }, [location?.pathname]);

    return (
        <div ref={canvasRef}/>
    );
};

export default PoolOfSquares;