export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps: { location },
}) => {
  const isNavigatingBetweenBusinessAndHome = (
    (prevRouterProps?.location.pathname === '/' && location.pathname === '/business/') ||
    (prevRouterProps?.location.pathname === '/business/' && location.pathname === '/')
  );

  // Retain scroll position when navigating between /business/ and /
  if (isNavigatingBetweenBusinessAndHome) {
    return false;
  }

  return true; // Default scroll behavior for other routes
};