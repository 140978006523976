import React from 'react'
import Link from '../utils/link'
import PoolOfSquares from "./pool";

const Footer = (props) => {
    return (
      <>
          <footer className='footer'>
              <div className='footer__pool'>
                  <PoolOfSquares location={props.location}/>
              </div>
              <div className='footer__inner'>
                  <div className='footer__top'>
                      <ul>
                          <li><Link to='/'>Talent /</Link></li>
                          <li><Link to='/business/'>Business /</Link></li>
                          <li><Link to='/careers/'>Jobs /</Link></li>
                          <li><Link to='/team/'>Team /</Link></li>
                          <li><Link to='/contact/'>Let's Chat /</Link></li>
                      </ul>
                  </div>

                  <div className='footer__bottom'>
                      <div className='footer__detail'>
                          <div className='footer__detail--left'>
                              <ul>
                                  <li><Link
                                    to={`mailto:hello@recruitsearchgrow.com.au`}>hello@recruitsearchgrow.com.au</Link>
                                  </li>
                                  <li><Link to={`tel:03 9887 8963`}>03 9887 8963</Link></li>
                              </ul>
                              <ul>
                                  <li><Link to='https://www.linkedin.com/company/rsg-recruit-search-grow/' target='_blank' rel='noopener noreferrer'>LinkedIn</Link></li>
                                  <li><Link to='https://www.instagram.com/recruit.search.grow/' target='_blank' rel='noopener noreferrer'>Instagram</Link></li>
                              </ul>
                          </div>

                          <div className='footer__detail--right'>
                              <p>Ground A, 41-43 Stewart St <br/> Richmond Victoria 3121</p>
                          </div>
                      </div>

                      <div className='footer__credit'>
                          <p>© Recruit Search Grow 2024. All rights reserved</p>
                          <Link to='/privacy-policy/'>Privacy Policy</Link>
                          <Link to='https://atollon.com.au/' target='_blank' rel='noopener noreferrer'>Design by Atollon</Link>
                      </div>
                  </div>
              </div>
          </footer>
      </>
    )
}

export default Footer
